<template>
    <v-card>
        <v-list-item>
            <v-list-item-avatar :color="typeColor"><v-icon dark>{{icon}}</v-icon></v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-subtitle>{{subTitle}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-img
                :src="image"
                height="194"
                position="center top"
                v-if="!!image"
        ></v-img>

        <v-card-subtitle class="pb-0" v-if="!!category">
            {{category}}
        </v-card-subtitle>

        <v-card-text class="headline pb-0" >
            {{title}}
        </v-card-text>

        <v-card-text v-if="!!this.$slots.default" class="text--primary">
            <slot></slot>
        </v-card-text>



        <v-card-actions v-if="!!link">
            <v-btn
                text
                color="accent"
                @click="goTo"
            >
                {{btnText}}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

export default {

    name: "vml-card-item",
    props: {
        title:{
            type:String,
            default: ''
        },
        subTitle:{
            type:String,
            default: ''
        },
        type:{
            type:String,
            default: 'movie'
        },
        image:{
            type:String,
            default: ''
        },
        category:{
            type:String,
            default: ''
        },
        link:{
            type:String,
            default: ''
        },
        btnText:{
            type:String,
            default: 'Open'
        }
    },

    methods: {
        goTo: function () {

            if (this.link !== "undefined") {
                if (this.type !== "undefined" && this.type === "document") {
                    window.open(this.link, '_blank');
                } else {
                    window.location.href = this.link;
                }
            }
        }
    },


    computed: {
        typeColor() {
            switch (this.type) {
                case 'faq':
                    return 'faqIconBGColor';

                case 'document':
                    return 'documentIconBGColor';

            }
            return 'movieIconBGColor';
        },

        icon(){
            switch (this.type) {
                case 'faq':
                    return 'mdi-help';

                case 'document':
                    return 'mdi-file-document';

            }
            return 'mdi-filmstrip';
        }


    }



};

</script>

<style scoped>

</style>
