import Vue from "vue";

import App from "./components/App";
import CardItem from "./components/CardItem";
import CardsLayout from "./components/CardsLayout";
const Components = {
    App,
    CardItem,
    CardsLayout
};

export {App}
export {CardItem}
export {CardsLayout};

Vue.component("vml-app",App);
Vue.component("vml-card-item",CardItem);
Vue.component("vml-cards-layout",CardsLayout);

// Export the library as a plugin
export default Components;
