import Vue from "vue"

import Vuetify from "vuetify/lib"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: '#ffd180',
        accent: '#ff8a80',
        error: '#b71c1c',
        topBarColor: undefined,
        searchColor:'#ffd180',
        movieIconBGColor:'#8c9eff',
        faqIconBGColor:'#80d8ff',
        documentIconBGColor:'#84ffff'

      },
      light: {
        primary: '#ff9800',
        accent: '#ff5722',
        error: '#b71c1c',
        topBarColor: '#ff9800',
        searchColor:'#ff5722',
        movieIconBGColor:'#3f51b5',
        faqIconBGColor:'#80d8ff',
        documentIconBGColor:'#00bcd4'
      }
    }
  }
})
