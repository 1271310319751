<template>
    <div>
    <v-row v-if="loading">
        <v-col  cols="12" sm="6" md="3" v-for="index in 4" :key="index">

            <v-skeleton-loader
                    type="card list-item-avatar-three-line, image, article"
            ></v-skeleton-loader>

        </v-col>
    </v-row>

    <v-row class="masonry" v-masonry transition-duration="0.3s" item-selector=".child" align="start" v-if="!loading">
        <v-col v-masonry-tile v-for="item in items" class="child" cols="12" sm="6" md="3" :key="item.key">

            <vml-card-item
                    :type="item.type"
                    :title="item.title"
                    :sub-title="item.subTitle"
                    :image="item.image"
                    :category="item.category"
                    :link="item.link"
                    :btnText="item.btnText"
            >
                <template v-if="!!item.description" slot="default">
                    {{item.description}}
                </template>
            </vml-card-item>
        </v-col>
    </v-row>
    </div>
</template>

<script>
    import Vue from "vue";
    import {VueMasonryPlugin} from 'vue-masonry';
    Vue.use(VueMasonryPlugin)
    export default {

        name: "vml-cards-layout",
        components: {
            "vml-card-item": () => import("./CardItem"),
        },
        props: {
            items: {
                type: Array
            },
            loading:{
                type: Boolean,
                default:false
            }
        }
    };

</script>

<style scoped>

</style>
