<template>
    <v-app id="inspire">
        <v-app-bar
                app
                clipped-left
                color="topBarColor"
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="!searchVisible" dark></v-app-bar-nav-icon>
            <v-toolbar-title
                    v-if="!searchVisible"
                    class="white--text"

            >{{title}}
            </v-toolbar-title>
            <v-btn icon v-on:click="toggleSearch" v-if="searchVisible" dark>
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-autocomplete
                    id="searchField"
                    v-model="searchSelect"
                    autofocus
                    class="minimize-height"
                    hide-details
                    height="20px"
                    hide-selected
                    hide-no-data

                    color="searchColor"

                    append-icon=""
                    :loading="searchLoading"
                    :search-input.sync="search"
                    :items="searchPossibilities"
                    :placeholder="localize.searchDefaultPlaceholder"
                    prepend-inner-icon="mdi-magnify"
                    v-if="searchVisible"
                    item-text="name"
                    item-value="link"
                    return-object
                    @change="searchGoTo"
                    solo
                    clearable
            >

                <template v-slot:item="{ item }" dark>
                    <v-list-item-avatar
                            v-bind:color="item.color"
                            class="headline font-weight-light white--text"
                    >
                        <v-icon dark>{{item.icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                        <v-list-item-subtitle v-text="item.category"></v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </v-autocomplete>
            <v-spacer v-if="!searchVisible"></v-spacer>
            <v-btn icon v-on:click="toggleSearch" v-if="!searchVisible" dark>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <v-dialog v-model="dialog" max-width="600px" v-if="!searchVisible">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            dark

                    >
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="headline">{{localize.settingDialogTitle}}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-switch
                                            v-model="darkTheme"
                                            v-bind:label="localize.settingDialogDarkThemeLabel"
                                            v-on:change="saveTheme"
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                            v-model="lang"
                                            v-bind:label="localize.settingDialogLanguageLabel"
                                            persistent-hint
                                            v-on:change="changeLanguage"
                                            :items="availableLanguages"
                                        >
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-app-bar>

        <v-navigation-drawer
                v-model="drawer"
                app
                clipped
                width="350"
        >
            <v-list dense>
                <slot name="menu"></slot>
            </v-list>
        </v-navigation-drawer>

        <v-main >


            <v-container
                    id="scroll-target"
                    fluid
                    style="padding-bottom:123px"
            >

                <slot></slot>
                <v-row>
                    <v-col sm="12">
                        <v-footer
                                light
                                padless
                                absolute
                        >
                            <v-card
                                    flat
                                    tile
                                    color="accent"
                                    class="text-center"
                                    width="100%"
                            >
                                <v-card-text>
                                    <v-btn
                                            icon
                                            href="https://www.facebook.com/pages/vysual/179582405562196"
                                            target="_blank"
                                            class="mx-4"
                                    >
                                        <v-icon >fab fa-facebook</v-icon>
                                    </v-btn>
                                    <v-btn
                                            icon
                                            href="https://twitter.com/vysualch"
                                            target="_blank"
                                            class="mx-4"
                                    >
                                        <v-icon>fab fa-twitter</v-icon>
                                    </v-btn>

                                    <v-btn
                                            icon
                                            href="https://www.linkedin.com/company/vysual"
                                            target="_blank"
                                            class="mx-4"
                                    >
                                        <v-icon>fab fa-linkedin</v-icon>
                                    </v-btn>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-text class="white--text">
                                    {{localize.AllRightsReserved}} &copy; vysual  {{ new Date().getFullYear() }}
                                </v-card-text>
                            </v-card>
                        </v-footer>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>

import Vue from "vue";
import vuetify from '../plugins/vuetify'
import VueCookies from 'vue-cookies'
import axios from 'axios';
Vue.config.productionTip = false;

Vue.use(VueCookies);

export default {
vuetify,
name: "vml-app",
    props: {
        lang:{
            type:String,
            default:'fr'
        },
        title:{
            type:String,
            default: 'Help vysual'
        },
        availableLanguages:{
            type:Array,
            default: function () {
                return ['fr','de','en']
            }
        },
        localize:{
            type: Object,
            default: function () {
                return {
                    settingDialogTitle: 'Settings',
                    settingDialogDarkThemeLabel: 'Dark theme',
                    settingDialogLanguageLabel: 'Language',
                    searchDefaultPlaceholder: 'Start typing to search',
                    AllRightsReserved: 'All rights reserved',
                }
            }
        }
    },
    methods: {
        onScroll (e) {
            this.offsetTop = e.target.scrollTop
        },
        toggleSearch: function () {
            this.searchVisible=!this.searchVisible;
        },

        searchGoTo: function(item){
            if(item && typeof item.link !=="undefined") {
                if(typeof item.type !=="undefined" && item.type ==="document"){
                    window.open(item.link , '_blank');
                }else {
                    window.location.href = item.link;
                }
            }
        },

        makeSearch: function (value) {

            axios
                .get('./index.php?json=searchCorrespondingDocuments',{
                    params:{
                        search:value
                    }
                })
                .then(response => {
                    if(!this._timerSearch) {
                        this.searchPossibilities =  response.data ;

                    }
                })
                .catch(error => {
                    console.log(error)
                }).finally(() => this.searchLoading = false);

        },

        saveTheme : function (item) {
            this.$cookies.set("darkTheme", item, {expires: '30d'});

            this.$vuetify.theme.dark=item;
        },

        changeLanguage : function (lang) {
            window.location.href = "./"+lang+"/";
        }
    },
    data: () => ({
        drawer: null,
        searchVisible:false,
        search:null,
        searchSelect:null,
        searchPossibilities:[],
        searchLoading:false,
        dialog:false,
        darkTheme:Vue.$cookies.isKey("darkTheme")  ? Vue.$cookies.get("darkTheme") ==="true"  : false,
        _timerSearch:null
    }),

    watch: {
        search(value) {
            this.searchPossibilities=[];

            var that=this;
            if(this._timerSearch){
                clearTimeout(this._timerSearch);
                this.searchLoading=false;
            }
            if(value ==="" || value ===null){
                return
            }

            this.searchLoading=true;

            this._timerSearch=setTimeout(function () {

                that._timerSearch=null;
                that.makeSearch(value)
            },350);
        }
    },

    created () {
        this.$vuetify.theme.dark =  Vue.$cookies.isKey("darkTheme")  ? Vue.$cookies.get("darkTheme") ==="true"  : false
    }
};

</script>

<style scoped>

</style>
